.contents_home .mainvisual {
  position: relative;
  width: 100%;
  height: 587.5px;
}

@media screen and (max-width: 767px) {
  .contents_home .mainvisual {
    height: calc(100vh - 45px);
  }
}

.contents_home .mainvisual .title {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 1.0s 0.5s linear;
  z-index: 1;
}

.contents_home .mainvisual .title.show {
  opacity: 1;
}

.contents_home .mainvisual .title .container {
  position: relative;
  top: 14%;
}

@media screen and (max-width: 767px) {
  .contents_home .mainvisual .title .container {
    top: 10%;
    margin: 0;
  }
}

.contents_home .mainvisual .title .main,
.contents_home .mainvisual .title .lead {
  position: relative;
  display: block;
}

.contents_home .mainvisual .title .main:before, .contents_home .mainvisual .title .main:after,
.contents_home .mainvisual .title .lead:before,
.contents_home .mainvisual .title .lead:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: opacity 2.0s linear;
  opacity: 1;
}

.contents_home .mainvisual .title .main:after,
.contents_home .mainvisual .title .lead:after {
  opacity: 0;
}

.contents_home .mainvisual .title .main {
  margin: 0;
  width: 58.00781%;
  padding-bottom: 20.6543%;
}

.contents_home .mainvisual .title .main:before {
  background-image: url(../images/contents/home/mainvisual_title_main.png);
}

@media screen and (max-width: 767px) {
  .contents_home .mainvisual .title .main {
    margin: 0 auto;
    width: 80.13333%;
    padding-bottom: 31.86667%;
  }
  .contents_home .mainvisual .title .main:before {
    background-image: url(../images/contents/home/mainvisual_title_main_sp.png);
  }
  .contents_home .mainvisual .title .main:after {
    background-image: url(../images/contents/home/mainvisual_title_main_black_sp.png);
  }
}

.contents_home .mainvisual .title .lead {
  margin: 3.5% 0 0;
  width: 46.04492%;
  padding-bottom: 7.4707%;
}

.contents_home .mainvisual .title .lead:before {
  background-image: url(../images/contents/home/mainvisual_title_lead.png);
}

@media screen and (max-width: 767px) {
  .contents_home .mainvisual .title .lead {
    margin: 4% auto 0;
    width: 82%;
    padding-bottom: 14.8%;
  }
  .contents_home .mainvisual .title .lead:before {
    background-image: url(../images/contents/home/mainvisual_title_lead_sp.png);
  }
  .contents_home .mainvisual .title .lead:after {
    background-image: url(../images/contents/home/mainvisual_title_lead_black_sp.png);
  }
}

@media screen and (max-width: 767px) {
  .contents_home .mainvisual .title.black .main:before,
  .contents_home .mainvisual .title.black .lead:before {
    opacity: 0;
  }
  .contents_home .mainvisual .title.black .main:after,
  .contents_home .mainvisual .title.black .lead:after {
    opacity: 1;
  }
}

.contents_home .mainvisual .slide {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s 0.5s linear;
}

.contents_home .mainvisual .slide.show {
  opacity: 1;
}

.contents_home .mainvisual .slide .item {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.contents_home .mainvisual .slide .item_01 {
  background-image: url(../images/contents/home/mainvisual_01.jpg);
}

@media screen and (max-width: 767px) {
  .contents_home .mainvisual .slide .item_01 {
    background-image: url(../images/contents/home/mainvisual_01_sp.jpg);
  }
}

.contents_home .mainvisual .slide .item_02 {
  background-image: url(../images/contents/home/mainvisual_02.jpg);
}

@media screen and (max-width: 767px) {
  .contents_home .mainvisual .slide .item_02 {
    background-image: url(../images/contents/home/mainvisual_02_sp.jpg);
  }
}

.contents_home .mainvisual .slide .item_03 {
  background-image: url(../images/contents/home/mainvisual_03.jpg);
}

@media screen and (max-width: 767px) {
  .contents_home .mainvisual .slide .item_03 {
    background-image: url(../images/contents/home/mainvisual_03_sp.jpg);
  }
}

.contents_home .section .section_header {
  display: flex;
}

.contents_home .section .section_header .title {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .contents_home .section .section_header._type1 {
    border-left: 5px solid #04a311;
    padding-left: 15px;
  }
  .contents_home .section .section_header._type2 {
    justify-content: center;
  }
}

@media print {
  .contents_home .section .section_header._type1 {
    border-left: 5px solid #04a311;
    padding-left: 15px;
  }
  .contents_home .section .section_header._type2 {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section .section_header._type1, .contents_home .section .section_header._type2 {
    border-left: 5px solid #04a311;
    padding-left: 15px;
  }
}

.contents_home .section#link {
  padding-top: 35px;
  padding-bottom: 35px;
  background: #ebebeb;
}

@media screen and (max-width: 767px) {
  .contents_home .section#link {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.contents_home .section#link .inner {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .contents_home .section#link .inner {
    display: block;
  }
}

.contents_home .section#link a {
  width: 32.42188%;
  height: 90px;
  letter-spacing: 0;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .contents_home .section#link a {
    width: auto;
    height: 70px;
  }
  .contents_home .section#link a:not(:first-child) {
    margin-top: 10px;
  }
}

.contents_home .section#link a span {
  padding-left: 15%;
}

@media screen and (max-width: 767px) {
  .contents_home .section#link a span {
    padding-left: 65px;
  }
}

.contents_home .section#link a span:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 80px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 55px 55px;
}

@media screen and (max-width: 767px) {
  .contents_home .section#link a span:before {
    width: 65px;
    background-size: 45px 45px;
  }
}

.contents_home .section#link a.builder span:before {
  background-image: url(../images/contents/home/link_img_builder.png);
}

.contents_home .section#link a.operator span:before {
  background-image: url(../images/contents/home/link_img_operator.png);
}

.contents_home .section#link a.elearning span:before {
  background-image: url(../images/contents/home/link_img_elearning.png);
}

.contents_home .section#news {
  position: relative;
  padding-top: 27px;
  padding-bottom: 27px;
  background: #ffffff;
  box-shadow: 0px 1px 8px -3px rgba(81, 81, 81, 0.8);
}

.contents_home .section#news .inner {
  display: flex;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .contents_home .section#news .inner {
    display: block;
    width: auto;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#news .section_header .title {
    width: width("../images/contents/home/news_title.png", 2);
    height: height("../images/contents/home/news_title.png", 2);
    background-image: url(../images/contents/home/news_title.png);
  }
}

@media print {
  .contents_home .section#news .section_header .title {
    width: width("../images/contents/home/news_title.png", 2);
    height: height("../images/contents/home/news_title.png", 2);
    background-image: url(../images/contents/home/news_title.png);
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#news .section_header .title {
    width: width(../images/contents/home/news_title_sp.png, 2);
    height: height(../images/contents/home/news_title_sp.png, 2);
    background-image: url(../images/contents/home/news_title_sp.png);
  }
}

.contents_home .section#news .section_body {
  display: flex;
  align-items: center;
  width: calc(100% - (326px / 2));
}

@media screen and (max-width: 767px) {
  .contents_home .section#news .section_body {
    display: block;
    width: auto;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#news .section_body .list {
    box-sizing: border-box;
    width: calc(100% - 225px);
    padding-left: 50px;
    padding-right: 20px;
  }
}

@media print {
  .contents_home .section#news .section_body .list {
    box-sizing: border-box;
    width: calc(100% - 225px);
    padding-left: 50px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#news .section_body .list {
    margin-top: 20px;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#news .section_body .list ul {
    height: 1.5em;
  }
}

@media print {
  .contents_home .section#news .section_body .list ul {
    height: 1.5em;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#news .section_body .list li {
    height: 1.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media print {
  .contents_home .section#news .section_body .list li {
    height: 1.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.contents_home .section#news .section_body .list li a {
  color: inherit;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .contents_home .section#news .section_body .list li .date {
    margin-right: 10px;
  }
}

@media print {
  .contents_home .section#news .section_body .list li .date {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#news .section_body .list li .date {
    display: block;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#news .section_body .more {
    width: 225px;
    height: 50px;
  }
}

@media print {
  .contents_home .section#news .section_body .more {
    width: 225px;
    height: 50px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#news .section_body .more {
    margin-top: 20px;
    height: 60px;
  }
}

.contents_home .section#sentricon {
  padding-top: 75px;
  padding-bottom: 75px;
  background-image: url(../images/contents/home/about_bg.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 100%;
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon {
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url(../images/contents/home/about_bg_sp.jpg);
    background-size: 420px 625px;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#sentricon .section_header .title {
    width: width("../images/contents/home/about_title.png", 2);
    height: height("../images/contents/home/about_title.png", 2);
    background-image: url(../images/contents/home/about_title.png);
  }
}

@media print {
  .contents_home .section#sentricon .section_header .title {
    width: width("../images/contents/home/about_title.png", 2);
    height: height("../images/contents/home/about_title.png", 2);
    background-image: url(../images/contents/home/about_title.png);
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon .section_header .title {
    width: width(../images/contents/home/about_title_sp.png, 2);
    height: height(../images/contents/home/about_title_sp.png, 2);
    background-image: url(../images/contents/home/about_title_sp.png);
  }
}

.contents_home .section#sentricon .section_body {
  position: relative;
  margin-top: 50px;
  margin-bottom: 135px;
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon .section_body {
    margin-top: 25px;
    margin-bottom: 95px;
    margin-left: -30px;
    margin-right: -30px;
  }
}

.contents_home .section#sentricon .section_body:before {
  content: "";
  display: block;
  width: 100%;
  height: 502px;
  background-image: url(../images/contents/home/about_img_01.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon .section_body:before {
    height: 459.5px;
    background-image: url(../images/contents/home/about_img_01_sp.jpg);
  }
}

.contents_home .section#sentricon .section_body .box {
  position: absolute;
  background-color: #f5f5f5;
}

@media screen and (min-width: 768px) {
  .contents_home .section#sentricon .section_body .box {
    width: 500px;
    right: 60px;
    bottom: -60px;
    padding: 55px 40px 60px 60px;
    box-sizing: border-box;
    font-size: 15px;
  }
}

@media print {
  .contents_home .section#sentricon .section_body .box {
    width: 500px;
    right: 60px;
    bottom: -60px;
    padding: 55px 40px 60px 60px;
    box-sizing: border-box;
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon .section_body .box {
    bottom: -35px;
    left: 30px;
    right: 30px;
    padding: 30px 25px 35px 25px;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#sentricon .section_body .box .lead {
    font-size: 21px;
  }
}

@media print {
  .contents_home .section#sentricon .section_body .box .lead {
    font-size: 21px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon .section_body .box .lead {
    font-size: 15px;
    margin-right: -10px;
  }
}

.contents_home .section#sentricon .section_body .box .lead strong {
  color: #04a311;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .contents_home .section#sentricon .section_body .box .lead strong {
    font-size: 25px;
  }
}

@media print {
  .contents_home .section#sentricon .section_body .box .lead strong {
    font-size: 25px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon .section_body .box .lead strong {
    font-size: 18px;
  }
}

.contents_home .section#sentricon .section_body .box ul {
  margin-top: 20px;
  margin-bottom: 15px;
}

.contents_home .section#sentricon .section_body .box ul li {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .contents_home .section#sentricon .section_body .box .more {
    margin: 50px auto 0;
    width: 325px;
  }
}

@media print {
  .contents_home .section#sentricon .section_body .box .more {
    margin: 50px auto 0;
    width: 325px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon .section_body .box .more {
    margin-top: 40px;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#sentricon .videos {
    display: flex;
    justify-content: space-between;
  }
}

@media print {
  .contents_home .section#sentricon .videos {
    display: flex;
    justify-content: space-between;
  }
}

.contents_home .section#sentricon .videos li {
  position: relative;
}

@media screen and (min-width: 768px) {
  .contents_home .section#sentricon .videos li {
    width: 47.85156%;
  }
}

@media print {
  .contents_home .section#sentricon .videos li {
    width: 47.85156%;
  }
}

.contents_home .section#sentricon .videos li a {
  position: relative;
  display: block;
  padding-bottom: 61.22449%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 768px) {
  .contents_home .section#sentricon .videos li p {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
  }
}

@media print {
  .contents_home .section#sentricon .videos li p {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon .videos li p {
    display: none;
  }
}

.contents_home .section#sentricon .videos li.item_01 a {
  background-image: url(../images/contents/home/about_img_02.jpg);
}

@media screen and (min-width: 768px) {
  .contents_home .section#sentricon .videos li.item_02 {
    margin-top: 150px;
  }
}

@media print {
  .contents_home .section#sentricon .videos li.item_02 {
    margin-top: 150px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#sentricon .videos li.item_02 {
    margin-top: 30px;
  }
}

.contents_home .section#sentricon .videos li.item_02 a {
  background-image: url(../images/contents/home/about_img_03.jpg);
}

.contents_home .section#case {
  position: relative;
  background: #f5f5f5;
}

@media screen and (max-width: 767px) {
  .contents_home .section#case {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.contents_home .section#case .cmn_container {
  position: relative;
}

@media screen and (min-width: 768px) {
  .contents_home .section#case .cmn_container {
    min-height: 500px;
  }
}

@media print {
  .contents_home .section#case .cmn_container {
    min-height: 500px;
  }
}

@media screen and (min-width: 1500px) {
  .contents_home .section#case .cmn_container {
    min-height: 100%;
    padding-bottom: 33.33333%;
  }
}

@media print {
  .contents_home .section#case .cmn_container {
    min-height: 100%;
    padding-bottom: 33.33333%;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#case .inner {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media print {
  .contents_home .section#case .inner {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#case .section_header .title {
    width: width("../images/contents/home/case_title.png", 2);
    height: height("../images/contents/home/case_title.png", 2);
    background-image: url(../images/contents/home/case_title.png);
  }
}

@media print {
  .contents_home .section#case .section_header .title {
    width: width("../images/contents/home/case_title.png", 2);
    height: height("../images/contents/home/case_title.png", 2);
    background-image: url(../images/contents/home/case_title.png);
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#case .section_header .title {
    width: width(../images/contents/home/case_title_sp.png, 2);
    height: height(../images/contents/home/case_title_sp.png, 2);
    background-image: url(../images/contents/home/case_title_sp.png);
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#case .section_body {
    margin-top: 35px;
    font-size: 15px;
    line-height: 1.7;
  }
}

@media print {
  .contents_home .section#case .section_body {
    margin-top: 35px;
    font-size: 15px;
    line-height: 1.7;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#case .section_body {
    margin-top: 20px;
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#case .section_body .more {
    margin-top: 50px;
    width: 325px;
    font-size: 15px;
  }
}

@media print {
  .contents_home .section#case .section_body .more {
    margin-top: 50px;
    width: 325px;
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#case .section_body .more {
    margin-top: 40px;
  }
}

.contents_home .section#case:after {
  content: "";
  display: block;
  background-image: url(../images/contents/home/case_img_01.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 768px) {
  .contents_home .section#case:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
}

@media print {
  .contents_home .section#case:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#case:after {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 66.66667%;
  }
}

.contents_home .section#builder {
  padding-top: 125px;
  padding-bottom: 125px;
  background-image: url(../images/contents/home/builder_bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
}

@media screen and (max-width: 767px) {
  .contents_home .section#builder {
    padding-top: 70px;
    padding-bottom: 65px;
    background-image: url(../images/contents/home/builder_bg_sp.jpg);
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#builder .inner {
    margin-left: 54.6875%;
  }
}

@media print {
  .contents_home .section#builder .inner {
    margin-left: 54.6875%;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#builder .section_header .title {
    width: 408px;
    height: 57.5px;
    background-image: url(../images/contents/home/builder_title.png);
  }
}

@media print {
  .contents_home .section#builder .section_header .title {
    width: 408px;
    height: 57.5px;
    background-image: url(../images/contents/home/builder_title.png);
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#builder .section_header .title {
    width: 299px;
    height: 41.5px;
    background-image: url(../images/contents/home/builder_title_sp.png);
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#builder .section_body {
    margin-top: 30px;
    font-size: 15px;
    line-height: 1.7;
  }
}

@media print {
  .contents_home .section#builder .section_body {
    margin-top: 30px;
    font-size: 15px;
    line-height: 1.7;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#builder .section_body {
    margin-top: 20px;
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#builder .section_body .more {
    margin-top: 50px;
    width: 325px;
    font-size: 15px;
  }
}

@media print {
  .contents_home .section#builder .section_body .more {
    margin-top: 50px;
    width: 325px;
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#builder .section_body .more {
    margin-top: 40px;
  }
}

.contents_home .section#know {
  padding-top: 75px;
  padding-bottom: 75px;
  background-image: url(../images/contents/home/know_bg.jpg);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: center;
}

@media screen and (max-width: 767px) {
  .contents_home .section#know {
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url(../images/contents/home/know_bg_sp.jpg);
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#know .section_header .title {
    width: width("../images/contents/home/know_title.png", 2);
    height: height("../images/contents/home/know_title.png", 2);
    background-image: url(../images/contents/home/know_title.png);
  }
}

@media print {
  .contents_home .section#know .section_header .title {
    width: width("../images/contents/home/know_title.png", 2);
    height: height("../images/contents/home/know_title.png", 2);
    background-image: url(../images/contents/home/know_title.png);
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#know .section_header .title {
    width: width(../images/contents/home/know_title_sp.png, 2);
    height: height(../images/contents/home/know_title_sp.png, 2);
    background-image: url(../images/contents/home/know_title_sp.png);
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#know .section_body {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
}

@media print {
  .contents_home .section#know .section_body {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#know .section_body {
    margin-top: 30px;
  }
}

.contents_home .section#know .section_body .item {
  display: block;
  box-sizing: border-box;
  padding: 0px 25px 25px;
  background: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .contents_home .section#know .section_body .item {
    width: 32.22656%;
  }
}

@media print {
  .contents_home .section#know .section_body .item {
    width: 32.22656%;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#know .section_body .item:not(:first-child) {
    margin-top: 20px;
  }
}

.contents_home .section#know .section_body .item:before {
  content: "";
  display: block;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
  .contents_home .section#know .section_body .item:before {
    height: 250px;
    background-size: 140px 140px;
  }
}

@media print {
  .contents_home .section#know .section_body .item:before {
    height: 250px;
    background-size: 140px 140px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#know .section_body .item:before {
    height: 160px;
    background-size: 110px 110px;
  }
}

.contents_home .section#know .section_body .item.basic:before {
  background-image: url(../images/contents/home/know_img_basic.png);
}

.contents_home .section#know .section_body .item.guard:before {
  background-image: url(../images/contents/home/know_img_guard.png);
}

.contents_home .section#know .section_body .item.point:before {
  background-image: url(../images/contents/home/know_img_point.png);
}

.contents_home .section#know .section_body .item .cmn_button {
  font-size: 13px;
}

.contents_home .section#contact {
  padding-top: 75px;
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .contents_home .section#contact {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}

.contents_home .section#contact .inner {
  padding: 60px;
  background: #f5f5f5;
}

@media screen and (max-width: 767px) {
  .contents_home .section#contact .inner {
    padding: 50px 25px;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#contact .section_header .title {
    width: width("../images/contents/home/contact_title.png", 2);
    height: height("../images/contents/home/contact_title.png", 2);
    background-image: url(../images/contents/home/contact_title.png);
  }
}

@media print {
  .contents_home .section#contact .section_header .title {
    width: width("../images/contents/home/contact_title.png", 2);
    height: height("../images/contents/home/contact_title.png", 2);
    background-image: url(../images/contents/home/contact_title.png);
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#contact .section_header .title {
    width: width(../images/contents/home/contact_title_sp.png, 2);
    height: height(../images/contents/home/contact_title_sp.png, 2);
    background-image: url(../images/contents/home/contact_title_sp.png);
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#contact .section_body {
    margin-top: 40px;
    font-size: 20px;
    text-align: center;
  }
}

@media print {
  .contents_home .section#contact .section_body {
    margin-top: 40px;
    font-size: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#contact .section_body {
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) {
  .contents_home .section#contact .section_body .more {
    width: 450px;
    margin: 50px auto 0;
    font-size: 15px;
  }
}

@media print {
  .contents_home .section#contact .section_body .more {
    width: 450px;
    margin: 50px auto 0;
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .contents_home .section#contact .section_body .more {
    margin-top: 40px;
  }
}
