@import 'includes/include';

.contents_home {

	.mainvisual {
		position: relative;
		width: 100%;
		height: (1175px / 2);

		@include SP {
			height: calc(100vh - 45px);
		}
		.title {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			opacity: 0;
			transition: opacity 1.0s 0.5s linear;
			z-index: 1;

			&.show {
				opacity: 1;
			}
			.container {
				position: relative;
				top: 14%;

				@include SP {
					top: 10%;
					margin: 0;
				}
			}
			.main,
			.lead {
				position: relative;
				display: block;

				&:before,
				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					transition: opacity 2.0s linear;
					opacity: 1;
				}
				&:after {
					opacity: 0;
				}
			}
			.main {
				margin: 0;
				width: (1188px / ($pc_base_w * 2)) * 100%;
				padding-bottom: (423px / ($pc_base_w * 2)) * 100%;

				&:before {
					background-image: url(../images/contents/home/mainvisual_title_main.png);
				}
				@include SP {
					margin: 0 auto;
					width: (601px / 750px) * 100%;
					padding-bottom: (239px / 750px) * 100%;

					&:before {
						background-image: url(../images/contents/home/mainvisual_title_main_sp.png);
					}
					&:after {
						background-image: url(../images/contents/home/mainvisual_title_main_black_sp.png);
					}
				}
			}
			.lead {
				margin: 3.5% 0 0;
				width: (943px / ($pc_base_w * 2)) * 100%;
				padding-bottom: (153px / ($pc_base_w * 2)) * 100%;

				&:before {
					background-image: url(../images/contents/home/mainvisual_title_lead.png);
				}
				@include SP {
					margin: 4% auto 0;
					width: (615px / 750px) * 100%;
					padding-bottom: (111px / 750px) * 100%;

					&:before {
						background-image: url(../images/contents/home/mainvisual_title_lead_sp.png);
					}
					&:after {
						background-image: url(../images/contents/home/mainvisual_title_lead_black_sp.png);
					}
				}
			}
			&.black {
				@include SP {
					.main,
					.lead {
						&:before {
							opacity: 0;
						}
						&:after {
							opacity: 1;
						}
					}
				}
			}
		}
		.slide {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			opacity: 0;
			transition: opacity 0.5s 0.5s linear;

			&.show {
				opacity: 1;
			}
			.item {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}
			.item_01 {
				background-image: url(../images/contents/home/mainvisual_01.jpg);

				@include SP {
					background-image: url(../images/contents/home/mainvisual_01_sp.jpg);
				}
			}
			.item_02 {
				background-image: url(../images/contents/home/mainvisual_02.jpg);

				@include SP {
					background-image: url(../images/contents/home/mainvisual_02_sp.jpg);
				}
			}
			.item_03 {
				background-image: url(../images/contents/home/mainvisual_03.jpg);

				@include SP {
					background-image: url(../images/contents/home/mainvisual_03_sp.jpg);
				}
			}
		}
	}

	.section {
		.section_header {
			display: flex;

			.title {
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
			@include PC {
				&._type1 {
					border-left: 5px solid $color_deepgreen;
					padding-left: 15px;
				}
				&._type2 {
					justify-content: center;
				}
			}
			@include SP {
				&._type1,
				&._type2 {
					border-left: 5px solid $color_deepgreen;
					padding-left: 15px;
				}
			}
		}
	}

	.section#link {
		padding-top: 35px;
		padding-bottom: 35px;
		background: $color_basicgray;

		@include SP {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		.inner {
			display: flex;
			justify-content: space-between;

			@include SP {
				display: block;
			}
		}
		a {
			width: (332px / $pc_base_w) * 100%;
			height: 90px;
			letter-spacing: 0;
			font-size: 14px;

			@include SP {
				width: auto;
				height: 70px;

				&:not(:first-child) {
					margin-top: 10px;
				}
			}
			span {
				padding-left: 15%;

				@include SP {
					padding-left: 65px;
				}
				&:before {
					position: absolute;
					top: 0;
					left: 0;
					content: "";
					display: block;
					width: 80px;
					height: 100%;
					background-position: center;
					background-repeat: no-repeat;
					background-size: 55px 55px;

					@include SP {
						width: 65px;
						background-size: 45px 45px;
					}
				}
			}
			&.builder {
				span {
					&:before {
						background-image: url(../images/contents/home/link_img_builder.png);
					}
				}
			}
			&.operator {
				span {
					&:before {
						background-image: url(../images/contents/home/link_img_operator.png);
					}
				}
			}
			&.elearning {
				span {
					&:before {
						background-image: url(../images/contents/home/link_img_elearning.png);
					}
				}
			}
		}
	}

	.section#news {
		position: relative;
		padding-top: 27px;
		padding-bottom: 27px;
		background: #ffffff;
		box-shadow: 0px 1px 8px -3px rgba(81,81,81,0.8);

		.inner {
			display: flex;
			width: 100%;
			align-items: center;

			@include SP {
				display: block;
				width: auto;
			}
		}
		.section_header {
			.title {
				$imginfo: imginfo('../images/contents/home/news_title.png', $sp:true);

				@include PC() {
					width: map-get($imginfo, W);
					height: map-get($imginfo, H);
					background-image:: map-get($imginfo, URL);
				}
				@include SP() {
					width: map-get($imginfo, SP_W);
					height: map-get($imginfo, SP_H);
					background-image:: map-get($imginfo, SP_URL);
				}
			}
		}
		.section_body {
			display: flex;
			align-items: center;
			width: calc(100% - (326px / 2));

			@include SP {
				display: block;
				width: auto;
			}
			.list {
				@include PC {
					box-sizing: border-box;
					width: calc(100% - 225px);
					padding-left: 50px;
					padding-right: 20px;
				}
				@include SP {
					margin-top: 20px;
				}
				ul {
					@include PC {
						height: 1.5em;
					}
				}
				li {
					@include PC {
						height: 1.5em;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					a {
						color: inherit;
						text-decoration: none;
					}
					.date {
						@include PC {
							margin-right: 10px;
						}
						@include SP {
							display: block;
							margin-bottom: 5px;
						}
					}
				}
			}
			.more {
				@include PC {
					width: 225px;
					height: 50px;
				}
				@include SP {
					margin-top: 20px;
					height: 60px;
				}
			}
		}
	}

	.section#sentricon {
		padding-top: 75px;
		padding-bottom: 75px;
		background-image: url(../images/contents/home/about_bg.jpg);
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: auto 100%;

		@include SP {
			padding-top: 50px;
			padding-bottom: 50px;
			background-image: url(../images/contents/home/about_bg_sp.jpg);
			background-size: 420px 625px;
		}
		.section_header {
			.title {
				$imginfo: imginfo('../images/contents/home/about_title.png', $sp:true);

				@include PC() {
					width: map-get($imginfo, W);
					height: map-get($imginfo, H);
					background-image:: map-get($imginfo, URL);
				}
				@include SP() {
					width: map-get($imginfo, SP_W);
					height: map-get($imginfo, SP_H);
					background-image:: map-get($imginfo, SP_URL);
				}
			}
		}
		.section_body {
			position: relative;
			margin-top: 50px;
			margin-bottom: 135px;

			@include SP {
				margin-top: 25px;
				margin-bottom: 95px;
				margin-left: -$sp_margin;
				margin-right: -$sp_margin;
			}
			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 502px;
				background-image: url(../images/contents/home/about_img_01.jpg);
				background-repeat: no-repeat;
				background-position: center top;
				background-size: cover;

				@include SP {
					height: (919px / 2);
					background-image: url(../images/contents/home/about_img_01_sp.jpg);
				}
			}
			.box {
				position: absolute;
				background-color: $color_lightgray;

				@include PC {
					width: 500px;
					right: 60px;
					bottom: -60px;
					padding: 55px 40px 60px 60px;
					box-sizing: border-box;
					font-size: 15px;
				}
				@include SP {
					bottom: -35px;
					left: $sp_margin;
					right: $sp_margin;
					padding: 30px 25px 35px 25px;
				}
				.lead {
					@include PC {
						font-size: 21px;
					}
					@include SP {
						font-size: 15px;
						margin-right: -10px;
					}
					strong {
						color: $color_deepgreen;
						font-weight: bold;

						@include PC {
							font-size: 25px;
						}
						@include SP {
							font-size: 18px;
						}
					}
				}
				ul {
					margin-top: 20px;
					margin-bottom: 15px;

					li {
						margin-top: 10px;
					}
				}
				.more {
					@include PC {
						margin: 50px auto 0;
						width: 325px;
					}
					@include SP {
						margin-top: 40px;
					}
				}
			}
		}
		.videos {
			@include PC {
				display: flex;
				justify-content: space-between;
			}
			li {
				position: relative;

				@include PC {
					width: ((980px / 2) / $pc_base_w) * 100%;
				}
				a {
					position: relative;
					display: block;
					padding-bottom: (600px / 980px) * 100%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
				p {
					@include PC {
						margin-top: 10px;
						font-size: 14px;
						text-align: center;
					}
					@include SP {
						display: none;
					}
				}

				&.item_01 {
					a {
						background-image: url(../images/contents/home/about_img_02.jpg);
					}
				}
				&.item_02 {
					@include PC {
						margin-top: 150px;
					}
					@include SP {
						margin-top: 30px;
					}
					a {
						background-image: url(../images/contents/home/about_img_03.jpg);
					}
				}
			}
		}
	}

	.section#case {
		position: relative;
		background: $color_lightgray;

		@include SP {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		.cmn_container {
			position: relative;

			@include PC {
				min-height: 500px;
			}
			@include PC(1500px) {
				min-height: 100%;
				padding-bottom: (1000 / 3000) * 100%;
			}
		}
		.inner {
			@include PC {
				position: absolute;
				width: 50%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
		.section_header {
			.title {
				$imginfo: imginfo('../images/contents/home/case_title.png', $sp:true);

				@include PC() {
					width: map-get($imginfo, W);
					height: map-get($imginfo, H);
					background-image:: map-get($imginfo, URL);
				}
				@include SP() {
					width: map-get($imginfo, SP_W);
					height: map-get($imginfo, SP_H);
					background-image:: map-get($imginfo, SP_URL);
				}
			}
		}
		.section_body {
			@include PC() {
				margin-top: 35px;
				font-size: 15px;
				line-height: 1.7;
			}
			@include SP() {
				margin-top: 20px;
				font-size: 13px;
			}
			.more {
				@include PC() {
					margin-top: 50px;
					width: 325px;
					font-size: 15px;
				}
				@include SP() {
					margin-top: 40px;
				}
			}
		}
		&:after {
			content: "";
			display: block;
			background-image: url(../images/contents/home/case_img_01.jpg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			@include PC {
				position: absolute;
				top: 0;
				right: 0;
				width: 50%;
				height: 100%;
			}
			@include SP {
				margin-top: 30px;
				margin-left: $sp_margin;
				margin-right: $sp_margin;
				padding-bottom: (1000 / (750 * 2)) * 100%;
			}
		}
	}

	.section#builder {
		padding-top: 125px;
		padding-bottom: 125px;
		background-image: url(../images/contents/home/builder_bg.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto 100%;

		@include SP() {
			padding-top: 70px;
			padding-bottom: 65px;
			background-image: url(../images/contents/home/builder_bg_sp.jpg);
		}
		.inner {
			@include PC() {
				margin-left: (560px / $pc_base_w) * 100%;
			}
		}
		.section_header {
			.title {
				@include PC() {
					width: (816px / 2);
					height: (115px / 2);
					background-image: url(../images/contents/home/builder_title.png);
				}
				@include SP() {
					width: (598px / 2);
					height: (83px / 2);
					background-image: url(../images/contents/home/builder_title_sp.png);
				}
			}
		}
		.section_body {
			@include PC() {
				margin-top: 30px;
				font-size: 15px;
				line-height: 1.7;
			}
			@include SP() {
				margin-top: 20px;
				font-size: 13px;
			}
			.more {
				@include PC() {
					margin-top: 50px;
					width: 325px;
					font-size: 15px;
				}
				@include SP() {
					margin-top: 40px;
				}
			}
		}
	}

	.section#know {
		padding-top: 75px;
		padding-bottom: 75px;
		background-image: url(../images/contents/home/know_bg.jpg);
		background-repeat: repeat-x;
		background-size: auto 100%;
		background-position: center;

		@include SP() {
			padding-top: 50px;
			padding-bottom: 50px;
			background-image: url(../images/contents/home/know_bg_sp.jpg);
		}
		.section_header {
			.title {
				$imginfo: imginfo('../images/contents/home/know_title.png', $sp:true);

				@include PC() {
					width: map-get($imginfo, W);
					height: map-get($imginfo, H);
					background-image:: map-get($imginfo, URL);
				}
				@include SP() {
					width: map-get($imginfo, SP_W);
					height: map-get($imginfo, SP_H);
					background-image:: map-get($imginfo, SP_URL);
				}
			}
		}
		.section_body {
			@include PC() {
				margin-top: 40px;
				display: flex;
				justify-content: space-between;
			}
			@include SP {
				margin-top: 30px;
			}
			.item {
				display: block;
				box-sizing: border-box;
				padding: 0px 25px 25px;
				background: #ffffff;
				box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
				text-decoration: none;

				@include PC() {
					width: (330px / $pc_base_w) * 100%;
				}
				@include SP() {
					&:not(:first-child) {
						margin-top: 20px;
					}
				}
				&:before {
					content: "";
					display: block;
					background-position: center;
					background-repeat: no-repeat;

					@include PC() {
						height: 250px;
						background-size: 140px 140px;
					}
					@include SP() {
						height: 160px;
						background-size: 110px 110px;
					}
				}
				&.basic:before {
					background-image: url(../images/contents/home/know_img_basic.png);
				}
				&.guard:before {
					background-image: url(../images/contents/home/know_img_guard.png);
				}
				&.point:before {
					background-image: url(../images/contents/home/know_img_point.png);
				}
				.cmn_button {
					font-size: 13px;
				}
			}
		}
	}

	.section#contact {
		padding-top: 75px;
		padding-bottom: 120px;

		@include SP {
			padding-top: 40px;
			padding-bottom: 80px;
		}
		.inner {
			padding: 60px;
			background: $color_lightgray;

			@include SP {
				padding: 50px 25px;
			}
		}
		.section_header {
			.title {
				$imginfo: imginfo('../images/contents/home/contact_title.png', $sp:true);

				@include PC() {
					width: map-get($imginfo, W);
					height: map-get($imginfo, H);
					background-image:: map-get($imginfo, URL);
				}
				@include SP() {
					width: map-get($imginfo, SP_W);
					height: map-get($imginfo, SP_H);
					background-image:: map-get($imginfo, SP_URL);
				}
			}
		}
		.section_body {
			@include PC {
				margin-top: 40px;
				font-size: 20px;
				text-align: center;
			}
			@include SP {
				margin-top: 30px;
			}
			.more {
				@include PC {
					width: 450px;
					margin: 50px auto 0;
					font-size: 15px;
				}
				@include SP {
					margin-top: 40px;
				}
			}
		}
	}
}
